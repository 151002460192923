import React from "react";

import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';

import Header from '../../components/header';
import Footer from '../../components/footer';
import Meta from '../../components/meta';

export const query = graphql`
  query {
    wallWings: file(relativePath: { regex: "/^live-edge-nephrite-jade-wall-feature.jpg$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    wallMoon: file(relativePath: { regex: "/^wall-moon.png$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    wallTopography: file(relativePath: { regex: "/^backlit-nephrite-jade-bookmatched-wallfeature.jpg$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    topoSpotlight: file(relativePath: { regex: "/^topo-spotlight.png$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    wingsSide: file(relativePath: { regex: "/^live-edge-BC-jade-wall-feature.jpg$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    lightWiring: file(relativePath: { regex: "/^light-wiring.png$/" }) {
      childImageSharp {
        fluid(quality:90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    },
    
  }
`

class SlabsAndSurfaces extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'diffusion',
      under576: false
    }

    this.handleCategoryClick = this.handleCategoryClick.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    if (window.innerWidth < 576 && !this.state.under576) {
      this.setState({under576: true});
    } else if (window.innerWidth > 576 && this.state.under576) {
      this.setState({under576: false});
    }
  }

  handleCategoryClick = (category) => {
    this.setState({
      tab: category
    })
  };

  render() {
    return(
      <div className="vh-100">
        <Meta title="Feature &amp; Walls" />
        <Header darkFont={false}/>

        <BackgroundImage className="p-5 container-fluid black-background" fluid={this.props.data.wallWings.childImageSharp.fluid} >
          <div className="row justify-content-center my-5">
            <div className="col-12 text-center mt-5 pt-0 pt-md-5">
              <p className="sans-serif text-white mt-5">Products</p>              
              { this.state.under576 ? 
                <h1 className="text-white serif d-inline-block">WALL FEATURES</h1>
                :
                <h1 className="text-white serif display-4 d-inline-block">WALL FEATURES</h1>
              }
              <hr className="col-1 horizontal-divider my-0"></hr>
              <h5 className="serif text-white mt-3 font-italic">Easy-to-mount wall hangings of reclaimed neprhite jade.</h5>
            </div>
          </div>
        </BackgroundImage>

        <div className="black-background container-fluid">
          <div className="row justify-content-center p-5">
            
            <h1 className="col-12 col-lg-10 text-center text-md-left text-light serif d-inline-block px-0 px-sm-3">INTRODUCTION</h1>
            <div className="col-12 col-md-6 col-lg-5 px-0 px-md-3 pb-3 pb-md-5 text-center text-md-left">
              <h5 className="text-light font-italic serif mb-1 mt-4 pr-0 pr-md-5">
                An accessible element for your home, office, or lobby. 
                Make a statement with our live-edge and backlit stone.
              </h5>
            </div>
            <div className="col-12 px-0 col-md-6 col-lg-5">
              <p className="text-center text-md-left text-light sans-serif mb-1 mt-4 pl-0 pl-md-5">
                Our nephrite Wall Features are lightweight and easy to install. 
                A narrow profile and modular mounting system eliminates the need for architectural re-design or major structural considerations. 
                Available in reclaimed, live-edge material and compatible with fully addressable LED backlighting.
              </p>
            </div>
          </div>
        </div>

        <div className="off-white-background container-fluid">
          <div className="row justify-content-center p-5">
            <div className="col-md-8 col-lg-6 col-xl-5 px-0 text-center">
              <h1 className="text-dark serif d-inline-block mb-0">LIVE-EDGE</h1>
              <hr className="col-1 horizontal-divider"></hr>
              <p className="text-dark sans-serif mt-4">
                These beautiful wall-mounted pieces are hand-crafted using reclaimed BC nephrite jade. 
                Each stone slab is wholly unique, and has endured millions of years of the harshest conditions. 
                It was forged beneath continents, and weathered by Canada's most powerful glaciers. A true masterpiece of both fire and ice.
              </p>
            </div>
          </div>
        </div>

        {/* TODO: fix sizing for wings image */}
        <div className="black-background container-fluid">
          <div className="row justify-content-center">
            <div className="col-8 px-0">
              <Img fluid={this.props.data.wingsSide.childImageSharp.fluid}/>
            </div>
          </div>
        </div>

        <div className="off-white-background container-fluid">
          <div className="row justify-content-center p-5">
            <div className="col-md-8 col-lg-6 col-xl-5 px-0 text-center">
              <h1 className="text-dark serif d-inline-block mb-0">BACKLIT</h1>
              <hr className="col-1 horizontal-divider"></hr>
              <p className="text-dark sans-serif mt-4">
                These striking stone hangings depict nephrite's true beauty with incredible depth. 
                Each piece tells a story of how the stone first came to be. 
                It's flowing forms are the result of hot, pressurized fluids forged deep beneath the pacific margin. 
                It took the weight of continents and oceans to produce these captivating patterns.
              </p>
            </div>
          </div>
        </div>

        <div className="black-background container-fluid">
          <div className="row justify-content-center p-5">
            <div className="col-12">
              <Img fluid={this.props.data.wallTopography.childImageSharp.fluid}/>
            </div>
          </div>
        </div>

        <div className="black-background container-fluid">
          <div className="row justify-content-around p-5">
            <div className="col-12 col-md-8 col-xl-5 text-center text-xl-left px-0 ml-0 ml-lg-5">
              { this.state.under576 ? 
                <h2 className="text-white serif d-inline-block">BACKLIGHTING SOLUTIONS</h2>
                :
                <h1 className="text-white serif d-inline-block">BACKLIGHTING SOLUTIONS</h1>
              }
              <div className="d-flex justify-content-center justify-content-xl-start my-4">
                <p className={`text-white sans-serif mr-3 mr-sm-5 py-2 product-specifications-tab ${this.state.tab === 'diffusion' ? 'active-tab' : ''}`} onClick={() => this.handleCategoryClick('diffusion')} >Diffusion</p>
                <p className={`text-white sans-serif mx-3 mx-sm-5 py-2 product-specifications-tab ${this.state.tab === 'setback' ? 'active-tab' : ''}`} onClick={() => this.handleCategoryClick('setback')} >Setback</p>
                <p className={`text-white sans-serif mx-3 mx-sm-5 py-2 product-specifications-tab ${this.state.tab === 'mapping' ? 'active-tab' : ''}`} onClick={() => this.handleCategoryClick('mapping')} >Mapping</p>
              </div>
              <div className={this.state.tab === 'diffusion' ? 'd-block' : 'd-none'}>
                <p className="text-white sans-serif mt-4">
                  Hot-spotting can be a major issue in typical backlit applications with stones such as onyx or marble. 
                  Hot-spotting occurs when a light source is clearly visible through a particular section of stone with a higher translucency. 
                </p>
                <p className="text-white sans-serif mt-4">
                  Our backlit wall feature products use Provencher nephrite specifically selected to maximize diffusion while maintaining character and depth. 
                  The result is a perfect, consistent glow with an impressive stone thickness of just 6.35mm (0.25 inches).
                </p>
              </div>
              <div className={this.state.tab === 'setback' ? 'd-block' : 'd-none'}>
                <p className="text-white sans-serif mt-4">
                  All Vancouver Jade backlit products have been developed to minimize the setback from our LED light sources, 
                  making our stone more accessible and versatile and allowing us to produce striking new designs. 
                </p>
                <p className="text-white sans-serif mt-4">
                  Our Wall Features are no exception with a setback of 2.5 cm (1 inch). All wall hangings are also available with a halo light effect for added depth.
                </p>
              </div>
              <div className={this.state.tab === 'mapping' ? 'd-block' : 'd-none'}>
                <p className="text-white sans-serif mt-4">
                  Our backlit products use cutting-edge addressable LED technology developed in collaboration 
                  with Youlitty Electronic Engineering and Oz Luminaire Lighting. 
                </p>
                <p className="text-white sans-serif mt-4">
                  Our lights and wall features are available with multiple preset lighting conditions designed to optimize appearance and depth and captivate onlookers.
                </p>
              </div>
            </div>
            <div className={`col-9 col-xl-5 align-self-center ${this.state.tab === 'diffusion' ? 'd-block' : 'd-none'}`}>
              <Img fluid={this.props.data.topoSpotlight.childImageSharp.fluid} alt="Vancouver Jade" />
            </div>
            <div className={`col-9 col-xl-5 align-self-center ${this.state.tab === 'setback' ? 'd-block' : 'd-none'}`}>
              <Img fluid={this.props.data.wallMoon.childImageSharp.fluid} alt="Vancouver Jade" />
            </div>
            <div className={`col-9 col-xl-5 align-self-center ${this.state.tab === 'mapping' ? 'd-block' : 'd-none'}`}>
              <Img fluid={this.props.data.lightWiring.childImageSharp.fluid} alt="Vancouver Jade" />
            </div>
          </div>
        </div>

        <div className="off-white-background container-fluid">
          <div className="row justify-content-center p-5">
            { this.state.under576 ? 
              <h2 className="text-center text-md-left text-dark serif d-inline-block">PRODUCT CHARACTERISTICS</h2>
              :
              <h1 className="text-center text-md-left text-dark serif d-inline-block text-nowrap">PRODUCT CHARACTERISTICS</h1>
            }
          </div>
          <div className="row justify-content-center pb-5">
            <div className="col-6 col-lg-2 border-right border-dark px-4 text-right text-lg-left">
              <h4 className="serif text-dark mb-4">Weight</h4>
              <p className="sans-serif text-dark small">1.23 kg per 900 cm<sub>2</sub></p>
            </div>
            <div className="col-6 col-lg-2 hide-border-on-mobile pl-4">
              <h4 className="serif text-dark mb-4">LED Setback</h4>
              <p className="sans-serif text-dark small">2.54 cm (1.00 inch)</p>
            </div>
            <div className="col-6 col-lg-2 border-right border-dark px-4 text-right text-lg-left">
              <h4 className="serif text-dark mb-4 ">Profile</h4>
              <p className="sans-serif text-dark small">3.175 (1.25 inches)</p>
            </div>
            <div className="col-6 col-lg-2 px-4">
              <h4 className="serif text-dark mb-4">Stone Thickness</h4>
              <p className="sans-serif text-dark small">6.35 mm (0.25 inches)</p>
            </div>
          </div>
        </div>

        <Footer />

      </div>
    )
  }
};

export default SlabsAndSurfaces;
